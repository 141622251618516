import axios, { AxiosError } from 'axios'
import { ApiReturn } from 'types/request'

type Params = {
  url: string
  data?: unknown
  params?: unknown
}

export const requestGet = async <Response>(
  props: Params
): Promise<ApiReturn<Response>> => {
  try {
    const response = await axios.request<Response>({
      method: 'get',
      ...props,
    })
    return { success: true, response: response.data }
  } catch (error) {
    const errorAsserted = error as AxiosError<Response>
    console.error(errorAsserted.message)
    return { success: false, error: errorAsserted }
  }
}
