import { Box } from '@mui/material'
import { withTypography } from 'components/Typography'

const Title = withTypography({
  variant: 'd3',
  color: 'secondary.rose.500',
  fontWeight: 'black',
  breakpoints: {
    sm: 'd4',
  },
})

const Subtitle = withTypography({
  variant: 'p1',
  color: 'secondary.mallard.700',
  breakpoints: {
    sm: 'p2',
  },
})

export const Text = () => (
  <Box>
    <Title>WELCOME</Title>
    <Title>TO PARADISE</Title>
    <Box mt='8px'>
      <Subtitle>Stay connected to everything you love!</Subtitle>
    </Box>
  </Box>
)
