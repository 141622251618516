export const colors = {
  primary: {
    coral: {
      100: '#FBE4E9',
      300: '#C5BDB6',
      500: '#9F9186',
      700: '#5F5750',
      900: '#201D1B',
    },
    driftwood: {
      100: '#ECE9E7',
      300: '#F4ADBC',
      500: '#EC778F',
      700: '#A24A5C',
      900: '#591D29',
    },
  },
  secondary: {
    mallard: {
      100: '#56C2D9',
      300: '#6798A5',
      500: '#003743',
      700: '#00232F',
      900: '#000B0D',
    },
    sand: {
      100: '#FEFAF9',
      300: '#FBF0EC',
      500: '#F8E6E0',
      700: '#CEA293',
      900: '#A55E46',
    },
    rose: {
      100: '#FBE0E4',
      300: '#F2A3AF',
      500: '#E96679',
      700: '#993F4C',
      900: '#4A181F',
    },
    darkDriftwood: {
      100: '#E6E4E2',
      300: '#B3ADA9',
      500: '#81776F',
      700: '#4D4743',
      900: '#1A1816',
    },
  },
  tertiary: {
    gold: {
      100: '#FFF5E1',
      300: '#FFE2A4',
      500: '#FFCE67',
      700: '#A24A5C',
      900: '#591D29',
    },
    limon: {
      100: '#F9F9E4',
      300: '#ECEDAD',
      500: '#E0E177',
      700: '#96974B',
      900: '#4C4C1F',
    },
  },
  shades: {
    white: '#FFFFFF',
    100: '#E3E3E3',
    200: '#C7C7C7',
    300: '#ABABAB',
    400: '#8F8F8F',
    500: '#737373',
    600: '#5C5C5C',
    700: '#454545',
    850: '#1F2937',
    black: '#000000',
  },
  success: {
    50: '#F0FDF4',
    100: '#DCFCE7',
    200: '#BBF7D0',
    300: '#86EFAC',
    400: '#4ADE80',
    500: '#22C55E',
    600: '#16A34A',
    700: '#15803D',
    800: '#166534',
    900: '#14532D',
  },
  warning: {
    50: '#FFFBEB',
    100: '#FEF3C7',
    200: '#FDE68A',
    300: '#FCD34D',
    400: '#FBBF24',
    500: '#F59E0B',
    600: '#D97706',
    700: '#B45309',
    800: '#92400E',
    900: '#78350F',
  },
  destructive: {
    50: '#FEF2F2',
    100: '#FEE2E2',
    200: '#FECACA',
    300: '#FCA5A5',
    400: '#F87171',
    500: '#EF4444',
    600: '#DC2626',
    700: '#B91C1C',
    800: '#991B1B',
    900: '#7F1D1D',
  },
  neutral: {
    50: '#F9FAFB',
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },
}
