import { Buffer } from 'buffer'
import { app } from 'config/app'

type Endpoint = string

type Params = {
  service: keyof typeof services
  endpoint: Endpoint
}

type ServiceParams = {
  endpoint: string
  data: unknown
}

const authString = `${app.zendeskAccount}/token:${app.zendeskApiToken}`

const services = {
  strapi: ({ endpoint, data }: ServiceParams) => ({
    url: `${app.strapiUrl}/api/${endpoint}`,
    data,
  }),
  zendesk: ({ endpoint, data }: ServiceParams) => ({
    url: `${app.zendeskUrl}/api/v2/${endpoint}`,
    data: { request: data },
    headers: {
      Authorization: `Basic ${Buffer.from(authString).toString('base64')}`,
    },
  }),
}

export const buildRequestPost =
  ({ service, endpoint }: Params) =>
  (data: unknown) =>
    services[service]({ endpoint, data })
