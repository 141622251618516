import { Container, Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useRef } from 'react'
import logo from 'assets/images/logo-pink-horizontal.svg'
import { useBreakpoints } from 'hooks/layout'
import bermuda from 'pages/Global/assets/bermuda.png'
import cayman from 'pages/Global/assets/cayman.png'
import global from 'pages/Global/assets/global.png'
import { Select } from './components/Select/Select'
import { Text } from './components/Text/Text'

const breakpoints = {
  small: 800,
}

const Logo = () => (
  <Box
    component='img'
    alt='logo'
    src={logo}
    sx={theme => ({
      [theme.breakpoints.down('xl')]: {
        width: '215px',
        height: '32px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '134px',
        height: '20px',
      },
    })}
  />
)

export const Global = () => {
  const imageRef = useRef<HTMLImageElement>(null)
  const { useMediaQuery, from } = useBreakpoints()
  const sm = useMediaQuery(from(breakpoints.small))
  return (
    <Container maxWidth='lg'>
      <Grid
        container
        alignItems='center'
        sx={{ height: '88px', position: 'absolute' }}
      >
        <Logo />
      </Grid>
      <Grid
        container
        pt='130px'
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            mt: '0px',
            pt: '0px',
            height: '100vh',
          },
          [theme.breakpoints.down(breakpoints.small)]: {
            justifyContent: 'center',
          },
        })}
      >
        <Grid
          xs={6}
          container
          justifyContent='center'
          alignItems='center'
          flexGrow={1}
        >
          <Grid>
            <Box mb='23px'>
              <Text />
            </Box>
            <Select imageRef={imageRef} />
          </Grid>
        </Grid>
        {!sm && (
          <Grid xs={6} container justifyContent='center' alignItems='center'>
            <Box component='img' src={bermuda} sx={{ display: 'none' }} />
            <Box component='img' src={cayman} sx={{ display: 'none' }} />
            <Box
              component='img'
              src={global}
              ref={imageRef}
              sx={theme => ({
                [theme.breakpoints.down('md')]: {
                  width: '350px',
                  height: '540px',
                },
              })}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  )
}
