import { Box, Link as MuiLink, styled } from '@mui/material'
import { useMemo, FC, RefObject } from 'react'
import { Typography } from 'components/Typography'
import { app } from 'config/app'
import bermuda from 'pages/Global/assets/bermuda.png'
import cayman from 'pages/Global/assets/cayman.png'
import global from 'pages/Global/assets/global.png'
import { Option, OptionDisabled } from './SelectOption'

type Image = RefObject<HTMLImageElement>

type Props = {
  imageRef: Image
}

const Link = styled(MuiLink)({ textDecoration: 'none' })

const handleImage = (imageRef: Image, image: string) => () => {
  if (imageRef.current) {
    imageRef.current.src = image
  }
}

export const Select: FC<Props> = ({ imageRef }) => {
  const [handleGlobal, handleBermuda, handleCayman] = useMemo(
    () => [global, bermuda, cayman].map(image => handleImage(imageRef, image)),
    [imageRef]
  )
  return (
    <Box onMouseLeave={handleGlobal}>
      <Link href={`${app.bermudaUrl}`}>
        <Option onMouseEnter={handleBermuda}>Bermuda</Option>
      </Link>
      <Link href={`${app.caymanUrl}`}>
        <Option onMouseEnter={handleCayman}>Cayman</Option>
      </Link>
      <OptionDisabled onMouseEnter={handleGlobal}>
        <Typography variant='h6' color='neutral.300' fontWeight='semiBold'>
          More coming soon...
        </Typography>
      </OptionDisabled>
    </Box>
  )
}
