import { useEffect } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { app } from 'config/app'
import { useGeolocation } from 'hooks/form/useMailList/useGeolocation'
import { Global } from 'pages/Global/Global'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Global />,
  },
])

export const Router = () => {
  const geolocation = useGeolocation()

  useEffect(() => {
    const { countryCode } = geolocation
    switch (countryCode) {
      case 'KY':
        window.location.replace(app.caymanUrl)
        break
      case 'BM':
        window.location.replace(app.bermudaUrl)
        break
      default:
    }
  }, [geolocation])

  return <RouterProvider router={router} />
}
