import { useState, useEffect } from 'react'
import { requestGet } from 'utils/api'

type GeolocationResponse = {
  country_name: string
  country_code: string
  city: string
}

export const useGeolocation = () => {
  const [geolocation, setGeolocation] = useState({
    countryName: '',
    countryCode: '',
    city: '',
  })

  useEffect(() => {
    const fetch = async () => {
      const { response } = await requestGet<GeolocationResponse>({
        url: 'https://ipapi.co/json/',
      })
      if (response) {
        setGeolocation({
          countryName: response.country_name,
          countryCode: response.country_code,
          city: response.city,
        })
      }
    }
    fetch()
  }, [])

  return geolocation
}
