import { East } from '@mui/icons-material'
import Grid from '@mui/material/Unstable_Grid2'
import { FC, ReactNode } from 'react'
import { Typography } from 'components/Typography'
import { SxProps } from 'types/utils'

type Props = {
  children: ReactNode
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

const withOption = (sx: SxProps) => {
  const Option: FC<Props> = ({ children, ...props }) => (
    <Grid
      container
      sx={theme => ({
        width: '360px',
        px: '16px',
        py: '14px',
        my: '8px',
        borderRadius: '4px',
        border: `1px solid ${theme.colors.neutral['300']}`,
        [theme.breakpoints.down('sm')]: {
          width: '327px',
        },
        ...(typeof sx === 'function' ? sx(theme) : sx),
      })}
      {...props}
    >
      {children}
    </Grid>
  )
  return Option
}

const OptionAround = withOption(theme => ({
  cursor: 'pointer',
  '&:hover': {
    bgcolor: theme.colors.secondary.mallard['500'],
    '& *': {
      color: theme.colors.shades.white,
    },
  },
}))

export const OptionDisabled = withOption(theme => ({
  cursor: 'default',
  [theme.breakpoints.down('sm')]: {
    width: '327px',
  },
}))

export const Option: FC<Props> = ({ children, ...props }) => (
  <OptionAround {...props}>
    <Grid xs={6}>
      <Typography
        variant='h6'
        color='secondary.mallard.500'
        fontWeight='semiBold'
      >
        {children}
      </Typography>
    </Grid>
    <Grid xs={6} container justifyContent='flex-end'>
      <East
        sx={theme => ({
          color: theme.colors.neutral['400'],
        })}
      />
    </Grid>
  </OptionAround>
)
